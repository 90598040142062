body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.login_signUp_Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  background-color: "background.paper";
  background: #f8f8f8;
  border: 1px solid black;
  border-radius: 10px;
  box-shadow: 24;
}
.maodalclose {
  text-align-last: right;
}
.modalDivider {
  height: 0px !important;
  border: 1px solid rgba(150, 149, 149, 0.7) !important;
  margin-bottom: 2 !important;
}
.modal_body {
  padding: 24px 55px 30px;
  flex: 1 0 0;
  max-height: 400px;
  overflow: auto;
}

.modalLink {
  margin-left: 5px;
}

.modalcontent {
  align-items: flex-end !important;
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .css-zy0slr-MuiGrid-root > .MuiGrid-item {
    max-width: 100% !important;
  }
  .mainlogo {
    width: 10% !important;
  }
}
@media only screen and (max-width: 767px) {
  .MuiGrid-item.category_btn {
    max-width: 100% !important;
    /* overflow: scroll; */
  }
  .MuiGrid-item {
    max-width: 100% !important;
  }
  .MuiGrid-item .MuiBox-root.css-1vgzt14 {
    justify-content: start;
  }
  .MuiGrid-root.MuiGrid-container.css-1ugc7yc-MuiGrid-root {
    justify-content: center;
  }
  .mainlogo {
    font-size: 18px !important;
    letter-spacing: normal !important;
  }
  .modal_Login .MuiBox-root {
    width: 80%;
  }
  .modal_Login .tab_pannel {
    width: 100%;
  }
}
